<template>
  <div class="custom-dialog" v-if="isDialogOpen">
    <div class="custom-dialog-content">
      <div class="custom-dialog-text text-left">
        <div class="top-content">
          <img
            :src="require(`@/assets/images/icons/ic_alert_triangle.svg`)"
            alt="warning"
          />
          <img
            :src="require(`@/assets/images/icons/ic_x_circle_grey.svg`)"
            alt="close"
            @click="handleCancel"
          />
        </div>
        <h3>Photo upload issue</h3>
        <p class="font-weight-thin">
          Facebook/Instagram browsers currently have issues with uploading
          photos.
        </p>
        <p class="font-weight-thin">
          You can skip photos and add them later through a link in email that
          AutoFlip will send after you submit.
        </p>
      </div>
      <div class="custom-dialog-buttons">
        <button class="custom-dialog-leave" @click="handleLeave">
          Skip photos
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomDialog",
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleCancel() {
      this.$emit("close");
    },
    handleLeave() {
      this.$emit("skip");
      this.$router.push({ name: "customer-detail", query: this.$route.query });
    },
  },
};
</script>
<style lang="scss">
.custom-dialog {
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    font-family: Avenir Next LT W05 Regular !important;
    font-weight: 600 !important;
    font-size: 18px;
    margin-top: 15px;
    line-height: 28px !important;
  }
  p {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 21px;
    color: #667085;
  }
  button {
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
  }
  .custom-dialog-content {
    background-color: #fff;
    padding: 24px;
    border-radius: 12px;
    max-width: 252px;
    height: 223px;
    text-align: center;
    .custom-dialog-text {
      width: 100%;
    }
    .custom-dialog-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 29px;
      .custom-dialog-leave {
        font-family: Avenir Next LT W05 Regular !important;
        font-weight: 500 !important;
        width: 120px;
        height: 44px;
        background-color: #1f8252;
        color: #fff;
        border: none;
        outline: none !important;
      }
    }
  }
}
@media screen and(max-width: 768px) {
  .custom-dialog {
    .custom-dialog-content {
      max-width: 300px !important;
      height: 347px !important;
      .custom-dialog-text {
        width: 100% !important;
        .top-content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        }
      }
    }
  }
}
</style>